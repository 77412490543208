import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

export const apiCall = async (data: {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: any;
  token?: string;
}) => {
  const { contentType, method, endPoint, body, token } = data;

  const header: any = {
    token: token ? token : localStorage.getItem("authToken"),
  };
  if (contentType) {
    header["content-type"] = contentType;
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

export const mockAPICall = (
  instance: any,
  apiCallID: string,
  apiData: object
) => {
  const msgSucessRestAPI = new Message(
    getName(MessageEnum.RestAPIResponceMessage)
  );
  msgSucessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceDataMessage),
    msgSucessRestAPI.messageId
  );
  msgSucessRestAPI.addData(
    getName(MessageEnum.RestAPIResponceSuccessMessage),
    apiData
  );
  instance[apiCallID] = msgSucessRestAPI.messageId;
  runEngine.sendMessage("Unit Test", msgSucessRestAPI);
};

export const redirectToPage = (navigation: any, path: string) => {
  navigation.navigate(path);
};
