import React from "react";

// Customizable Area Start
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Toolbar,
  Typography,
  styled,
  Modal,
  Container
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import LandingPageController from "./LandingPageController";
import ReCAPTCHA from "react-google-recaptcha";
import {
  contactUs,
  bg2,
  bg3,
  bg4,
  business,
  close,
  coaches,
  individual,
  EmailSuccessIcon,
  landingPageVideo,
  blurImage2,
  blurImage3,
} from "./assets";
import "./landingPage.css";
import TypeWriter from "typewriter-effect";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import ErrorIcon from "@material-ui/icons/Error";
import { Formik } from "formik";
import Footer from "../../../components/src/Footer/Footer.web";
// Customizable Area End

// Customizable Area Start
const useStyles = {
  textBetween: {
    fontSize: "18px",
    fontWeight: 600,
    cursor: "pointer",
    dispaly: "flex",
    opacity: 0.8,
    "&:hover": {
      color: "#FFFFFF",
      opacity: 1,
    },
  } as const,
  text_color_purple: {
    color: "#390689",
    "&:hover": {
      color: "#390689",
    },
  } as const,
  text_color_white: {
    color: "rgba(238, 238, 238, 0.8)",
  } as const,

  btnPointr : {
    cursor: "pointer",
  } as const,

  text: {
    fontSize: "48px",
    fontFamily: "Open Sans",
    fontWeight: 600,
    letterSpacing: "0em",
    lineHeight: "57.6px",
    textAlign: "left",
    color: "rgba(239, 239, 239, 1)",
  } as const,
  textSub: {
    marginBottom: "20px",
    marginTop: "20px",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "38.4px",
    letterSpacing: "0em",
    color: "rgba(239, 239, 239, 1)",
  } as const,
  businessBtn: {
    height: "54px",
    width: "190px",
    borderRadius: "30px",
    textTransform: "none",
    backgroundColor: "rgba(0,189,255,0.4)",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "20px",
    padding: "10px 16px",
    fontFamily: "Open Sans",
    transition: "0.25s",
    "&:hover": {
      backgroundColor: "transparent",
      border: "3px solid #00BDFF",
      boxShadow: "inset 11.5em 0 0 0 rgba(0, 189, 255, 0.4)",
    },
    "&:focus": {
      backgroundColor: "transparent",
      border: "3px solid #00BDFF",
      boxShadow: "inset 11.5em 0 0 0 rgba(0, 189, 255, 0.4)",
    },
  } as const,
  individualBtn: {
    width: "212px",
    height: "54px",
    border: " 3px solid #24E09C",
    borderRadius: "30px",
    backgroundColor: "transparent",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "10px 20px",
    fontWeight: 700,
    marginLeft: "36px",
    fontSize: "20px",
    fontFamily: "Open Sans",
    "&:hover": {
      backgroundColor: "#24e09c",
    },
    "@media (max-width: 769px)": {
      marginLeft: "0px",
    },
  } as const,
  subText1: {
    color: "#666666",
    fontWeight: 600,
    fontSize: "24px",
    textAlign: "center",
    lineHeight: "36px",
    fontFamily: "Open Sans",
    "@media (max-width:360px)": {
      lineHeight: "27px",
      fontSize: "18px",
      textAlign: "justify",
    },
  } as const,
mainDivLogin: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "93px",
    backgroundColor: "#390689",
    opacity: 0.9,
    padding: "0 48px",
    "@media(max-width:600px)":{
      height:"80px",
      padding:"0 16px",
    }
  } as const,
  mainDivScroll: {} as const,
  mainDivMenu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "480px",
    "@media (max-width: 900px)": {
      display: "none",
    },
  } as const,
  menuIcn: {
    display: "none",
    color: "#FFFFFF",
    cursor: "pointer",
    "@media (max-width: 900px)": {
      display: "block",
    },
  } as const,
  menuIcn_white: {
    color: "#FFFFFF"
  } as const,
  menuIcn_purple: {
    color: "#390689"
  } as const,
  heroSectDiv: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    height: "900px",
    width: "100%",
    display: "flex",
    position: "relative",
    background: "linear-gradient(90deg, #390689 3%, rgba(57, 6, 137, 0.5) 50%, rgba(57, 6, 137, 0) 100%)",
    "& video" : {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }
  } as const,
  heroSect: {
    marginTop: "347px",
    "@media (max-width: 1260px)": {
      marginTop: "300px",
    },
    "@media (min-width: 601px) and (max-width: 1280px)": {
      marginTop: "220px",
    },
    "@media(max-width:600px)": {
      marginTop: "170px",
    },
    "@media(max-width:414px)": {
      marginTop: "140px",
    },
  } as const,
  btnDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "32px",
    "@media (max-width: 769px)": {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      width: "220px",
      alignItems: "unset",
    },
  } as const,
  circle: {
    backgroundColor: "#3D0E9C",
    borderRadius: "50%",
    width: "125px",
    height: "125px",
    display: "flex",
    flexShrink: 0,
    "@media (max-width: 500px)": {
      width: "80px",
      height: "80px",
    },
  } as const,
  modalCircle: {
    backgroundColor: "#3D0E9C",
    borderRadius: "50%",
    width: "125px",
    height: "125px",
    display: "flex",
    flexShrink: 0,
    "@media (max-width: 500px)": {
      width: "70px",
      height: "70px",
    },
  } as const,
  contactContent: {
    fontFamily: "Martian Mono",
    fontSize: "42px",
    lineHeight: "50.4px",
    textAlign: "left",
    color: "#FAFAFA",
    fontWeight: 600,
  } as const,
  contactDescri: {
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "36px",
    textAlign: "left",
    color: "#FAFAFA",
  } as const,
  circleText: {
    fontWeight: 600,
    fontSize: "56px",
    lineHeight: "67.2px",
    color: "#FFFFFF",
    marginLeft: "-82px",
    fontFamily: "Martian Mono",
    "@media (min-width:360px) and (max-width:414px)": {
      marginLeft: "-50px",
      fontSize: "36px",
      lineHeight: "43.2px",
      letterSpacing: 0,
    },
  } as const,
  cardOne: {
    background: `url(${blurImage2})`,
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    backgroundSize: "cover !important",
    padding: "24px",
    border: "1.5px solid",
    borderColor: "transparent",
    height: "fit-content",
    width: "576px",
    borderRadius: "20px",
    color: "#FAFAFA",
    boxSizing:"border-box",
    boxShadow:"none",
  } as const,
  cardTwo: {
    background: `url(${blurImage3})`,
    backgroundRepeat: "no-repeat",
    color: "#FAFAFA",
    overflow: "hidden",
    backgroundSize: "cover !important",
    boxShadow:"none",
    padding: "24px",
    border: "1.5px solid",
    boxSizing:"border-box",
    borderRadius: "20px",
    borderColor: "transparent",
    height: "fit-content",
    width: "576px",
  } as const,
  fotterBp: {
  } as const,
  textSze: {
    fontFamily: "open sans !important",
    fontSize: "14px",
    fontWeight: 400,
  } as const,
  textSzes: {
    fontFamily: "open sans !important",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "19.07px",
  } as const,
  textSzess: {
    fontFamily: "open sans !important",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21.79px",
    fontColor: "#FAFAFA",
  } as const,
  
  setMargin: {
    marginTop: "-80px",
    "@media (max-width:1000px)": {
      marginTop: "0px !important",
    },
  } as const,
  setMarginAgain: {
    marginTop: "-108px",
    "@media (max-width:1000px)": {
      marginTop: "0px !important",
    },
  } as const,
  poweredByGrid: {
    padding: "10px",
    color: "#FAFAFA",
    gap: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom:"10px",
    paddingTop:"35px",
    "@media (max-width:960px)":{
        alignItems: "center"
    }
  } as const,
  service: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
    maxWidth: "100%",
  } as const,
  cardOneBox: {
    background: `linear-gradient(270deg, rgba(57, 6, 137, 0.65) 0%, rgba(57, 6, 137, 0) 100%), url(${bg2})`,
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    width: "100%",
    height: "655px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundPosition: "left !important",
    "@media(max-width:600px)":{
      background: `linear-gradient(348.8deg, #390689 36.68%, rgba(57, 6, 137, 0.15) 96.25%), url(${bg2})`,
    }
  } as const,
  cardTwoBox: {
    background: `linear-gradient(90deg, rgba(57, 6, 137, 0.65) 0%, rgba(57, 6, 137, 0) 100%), url(${bg3})`,
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    width: "100%",
    height: "636px",
    display: "flex",
    alignItems: "center",
    marginTop: "-2px",
    backgroundPosition: "right !important",
    "@media(max-width:600px)":{
      background: `linear-gradient(348.8deg, #390689 36.68%, rgba(57, 6, 137, 0.15) 96.25%), url(${bg3})`,
    }
  } as const,
  cardThreeBox: {
    background: `linear-gradient(270deg, rgba(57, 6, 137, 0.65) 0%, rgba(57, 6, 137, 0) 100%), url(${bg4})`,
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    width: "100%",
    height: "626px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundPosition: "center !important",
    "@media(max-width:600px)":{
      background: `linear-gradient(348.8deg, #390689 36.68%, rgba(57, 6, 137, 0.15) 96.25%), url(${bg4})`,
    }
  } as const,
  howItWork:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop:"88px",
    paddingBottom:"128px",
    "@media(max-width:599px)":{
      paddingTop:"38px",
      paddingBottom:"64px",
    },
    "@media(max-width:960px)":{
      paddingTop:"54px",
      paddingBottom:"86px",
    }
  } as const,
  bussCard: {
    backgroundImage: `url(${business})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    display: "flex",
    fontFamily: "Martian Mono",
    color: "#FAFAFA",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "10px",
    "@media (max-width:959px)": {
      height: "auto",
      objectFit: "cover",
      "& .cardTitle": {
        marginTop: "295px !important"
      }
    },
    "@media (max-width:414px)": {
      maxHeight: "460px",
      minHeight: "460px",
      objectFit: "cover",
      "& .cardTitle": {
        marginTop: "295px !important"
      }
    }
  } as const,
  individualCard: {
    backgroundImage: `url(${individual})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    display: "flex",
    fontFamily: "Martian Mono",
    color: "#FAFAFA",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "10px",
    "@media (max-width:959px)": {
      height: "auto",
      objectFit: "cover",
      "& .cardTitle": {
        marginTop: "295px !important"
      }
    },
    "@media (max-width:414px)":{
      maxHeight:"460px",
      minHeight:"460px",
      objectFit:"cover",
      "& .cardTitle":{
        marginTop:"295px !important"
      }
    }
  } as const,
  coachCard: {
    backgroundImage: `url(${coaches})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
    display: "flex",
    fontFamily: "Martian Mono",
    color: "#FAFAFA",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "10px",
    "@media (max-width:959px)": {
      height: "auto",
      objectFit: "cover",
      "& .cardTitle": {
        marginTop: "295px !important"
      }
    },
    "@media (max-width:414px)":{
      maxHeight:"460px",
      minHeight:"460px",
      objectFit:"cover",
      "& .cardTitle":{
        marginTop:"295px !important"
      }
    }
  } as const,
  btnPointer: {
    "&:hover": {
      cursor: "pointer",
      fontWeight: "Bold !important"
    }
  } as const,
  loginWhiteImg: {
    cursor: "pointer",
    opacity: "1",
    width: "1.5rem",
    marginTop: "-0.1rem",
    position: "absolute",
    "@media (max-width:486px)": {
      cursor: "pointer",
      opacity: "1",
      position: "absolute",
      marginRop: "-0.1rem",
      width: "92px",
      height: "14px",
    }

  } as const,
  loginPurpleImg: {
    cursor: "pointer",
    opacity: "0",
    marginLeft: "0.0rem",
    marginTop: "-0.1rem",
    width: "1.5rem",
    position: "absolute",

  } as const,
  loginWork: {
    height: "20px",
    marginLeft: "15px",
    marginTop: "10px",
    cursor: "pointer",
  } as const,
  iconArrange: {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap:"24px",
    width: "423.42px",
    height: "18px",
    "@media (max-width: 996px)":{
        display:"none",
    }
  } as const,
  afterIconArrange: {
    "@media (max-width: 996px)":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        alignContent: "center",
    }
  } as const,
  loginDiv: {
    width: "117px",
    border: "3px solid white",
    cursor: "pointer",
    height: "45px",
    borderRadius: "32px",
    boxShadow: "inset #FFF 0 0 0 0",
    transition: "ease-in 0.4s",
    boxSizing: "border-box",
    "&:hover": {
      cursor: "pointer",
      background: "rgba(255, 255, 255, 1) !important",
      boxShadow: "inset 400px 0 0 0 rgba(255, 255, 255, 1) !important",
    },
    "&:hover $loginPurpleImg": {
      opacity: "1"
    },
    "&:hover $loginWhiteImgTop": {
      opacity: "0"
    },
    "&:hover $loginText": {
      cursor: "pointer",
      color: "#5F0AE5",
      transition: "width 0.3 ease-in-out"
    }
  } as const,
  loginText: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: "bolder",
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "-23px",
    marginLeft: "44px",
    color: "#FAFAFA",
  } as const,
  loginWhiteImgTop: {
    cursor: "pointer",
    opacity: "0",
    width: "1.5rem",
    marginTop: "-0.1rem",
    position: "absolute",
  } as const,
  loginPurpleImgTop: {
    cursor: "pointer",
    marginLeft: "0.0rem",
    marginTop: "-0.1rem",
    width: "1.5rem",
    position: "absolute",

  } as const,
  loginWorkTop: {
    height: "20px",
    marginLeft: "15px",
    marginTop: "10px",
    cursor: "pointer",
  } as const,
  arrowMarginBottom: {
    marginTop: "40px",
    height: "48px",
    width: "48px",
    cursor: "pointer",
    "@media (max-width:414px)": {
      marginTop: "unset",
      marginBottom: "102px",
    }
  } as const,
  loginDivTop: {
    cursor: "pointer",
    border: "3px solid #5F0AE5",
    borderRadius: "32px",
    width: "117px",
    height: "45px",
    boxShadow: "inset #FFF 0 0 0 0",
    transition: "ease-in 0.4s",
    boxSizing: "border-box",
    "&:hover": {
      "&:hover": {
        cursor: "pointer",
        background: "#5F0AE5 !important",
        boxShadow: "inset 400px 0 0 0 #5F0AE5 !important",
      },
    },
    "&:hover $loginPurpleImgTop": {
      opacity: "0"
    },
    "&:hover $loginWhiteImgTop": {
      opacity: "1"
    },
    "&:hover $loginTextTop": {
      cursor: "pointer",
      color: "#FAFAFA",
      transition: "width 0.3 ease-in-out"
    }
  } as const,
  loginTextTop: {
    fontFamily: "Open Sans",
    fontSize: "18px",
    fontWeight: "bolder",
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "-23px",
    marginLeft: "44px",
    color: "#5F0AE5",
  } as const,
  mobileNavigation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gap: "20px",
  } as const,
};

const CustomReCAPTCHA = styled(ReCAPTCHA)({
  "& div>div>div>iframe": {
    borderRadius: "0px",
    overflow: "hidden",
    "@media(max-width: 400px)": {
      width: " 304px !important",
      transform: " scale(0.6)",
      transformOrigin: "0 0",
    },
  },
});
const LoginButtonWrapper = styled("div")({
  cursor: "pointer",
  "@media (max-width: 900px)": {
    display: "none",
  },
 
  "& .login_slide:hover, .login_slide:focus": {
    border: "3px solid #ffffff",
    color: "#5f0ae5",
    cursor: "pointer",
  },
  "& .login_slide:hover svg": {
    fill: "#5f0ae5 !important",
  },
  "& .login_slide:focus svg": {
    fill: "#5f0ae5 !important",
  },
});
const MainContainer = styled(Container)(({ theme }) => ({
  "@media(min-width:1288px)": {
    maxWidth: "1288px !important",
  },
  "& .container": {
    width: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      maxWidth: "55%",
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  "& .left-content": {
    flex: "0 0 512px",
    maxWidth: "512px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flex: "0 0 100%",
      maxWidth: "100%",
    },
  },
  "& .right-content": {
    flex: "0 0 calc(100% - (512px + 64px))",
    maxWidth: "calc(100% - (512px + 64px))",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flex: "0 0 100%",
      maxWidth: "100%",
    },
  },
  "& .right-max-width-content": {
    margin: "auto",
    width: "100%",
  },
  "& .form-wrapper": {
    alignItems: "center",
    display: "flex",
    gap: "64px",
    width: "100%",
    paddingTop: "128px",
    paddingBottom: "128px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      paddingTop: "100px",
      paddingBottom: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
      paddingBottom: "80px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "64px",
      paddingBottom: "64px",
    },
  },
  "& .descContactTitle": {
    width: "auto",
    maxWidth: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "55%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
}));

const MainContainer1 = styled(Container)({
  "@media(min-width:1288px)": {
    maxWidth: "1288px !important",
  },
  "& .card-one-end":{
    display:"flex",
    justifyContent:"flex-end",
  },
  "& .card-two-end":{
    display:"flex",
    justifyContent:"flex-start",
  }
});
const DotContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Martian Mono",
  fontSize: "56px",
  fontWeight: 600,
  lineHeight: "67.2px",
  textAlign: "center",
  color: "#390689",
  textTransform:"uppercase",
  [theme.breakpoints.down("sm")]:{
    fontSize: "46px",
    lineHeight: "54.2px",
  },
  [theme.breakpoints.down("xs")]:{
    fontSize: "36px",
    lineHeight: "43.2px",
  },
  "@media(max-width:380px)":{
    fontSize: "32px",
    lineHeight: "39.2px",
  },
  "& .circle": {
    height: "125px",
    width: "125px",
    backgroundColor: "#390689",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "flex-end",
    boxSizing: "border-box",
    color: "#FFF",
    padding: "5px",
    [theme.breakpoints.down("sm")]:{
      height: "100px",
      width: "100px",
    },
    [theme.breakpoints.down("xs")]:{
      height: "80px",
      width: "80px",
      padding: "4px",
    },
    "@media(max-width:380px)":{
      height: "70px",
      width: "70px",
    },
  },
}));
export class LandingPage extends LandingPageController {
  
  displayTouchedErrorMessage = (errors: string | undefined, touched: boolean | undefined) => {
    return (
      errors &&
      touched && (
        <ErrorTypography>
          <ErrorIcon
            style={{
              fontSize: "small",
              marginRight: "3px",
            }}
          />
          {errors}
        </ErrorTypography>
      )
    );
  };
  displayErrorMessage = (errors: string | undefined) => {
    return (
      errors &&(
        <ErrorTypography>
          <ErrorIcon
            style={{
              fontSize: "small",
              marginRight: "3px",
            }}
          />
          {errors}
        </ErrorTypography>
      )
    );
  };
  render() {

    const { classes } = this.props;
    
    return (
      <React.Fragment>
        <Grid
          onScroll={this.handleNavBarScrollColor}
          container
          style={{ overflowX: "hidden" }}
        >
          {this.state.hideTop ? null : (
            <Grid item xs={12}>
              <AppBar
                position="relative"
                style={{
                  backgroundColor: "#EDEFEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                }}
              >
                <Toolbar
                  className="toolbar"
                  style={{
                    color: "#390689",
                    fontFamily: "Open Sans",
                    fontWeight: 700,
                    fontSize: "14px",
                    cursor: "pointer",
                    textAlign: "center"
                  }}
                  onClick={this.handleRegisterNavigate}
                >
                  Get ready for a reimagined experience with Coachy
                </Toolbar>
                <img
                  src={close}
                  style={{
                    position: "absolute",
                    right: "20px",
                    cursor: "pointer",
                    backgroundSize: "cover",
                    objectFit: "cover",
                  }}
                  onClick={this.hideTopBar}
                  alt="close"
                />
              </AppBar>
            </Grid>
          )}
          <div style={{ position: "sticky", width: "100%", zIndex: 2, top: 0 }}>
            <Grid
              item
              xs={12}
              style={{ position: "sticky", width: "100%", zIndex: 2 }}
            >
              <div
                className={
                  this.state.scrolling
                    ? `mainDivLogin bg_white`
                    : `mainDivLogin bg_purple`
                }
              >
                <div
                  style={{ height: "37px", width: "165.5px" }}
                  className="logoImg"
                >
                  <img
                    src={this.returnSrc()}
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      objectFit: "cover",
                    }}
                    alt="Logo"
                  />
                </div>

                <div className={classes.mainDivMenu}>
                  <Typography
                    onClick={this.handleServices}
                    ref={this.scrollRefServices}
                    className={
                      this.state.scrolling
                        ? `${classes.textBetween} ${"hover_dropdown"} ${
                            classes.text_color_purple
                          } `
                        : `${classes.textBetween} ${"hover_dropdown"} ${
                            classes.text_color_white
                          }`
                    }
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "24.51px",
                      textAlign: "center",
                      display: "flex",
                      position: "relative",
                      alignItems: "center",
                      height: "100%",
                    }}
                    data-test-id="services-btn"
                  >
                    Services
                    <span className="service_arrow">
                      <KeyboardArrowDownOutlinedIcon />
                    </span>
                    <div
                      className={
                        this.returnServiveClassName()
                      }
                    >
                      <ul>
                        <li onClick={this.redirectToComingSoonPage}>For Business</li>
                        <li onClick={this.individualHandleBtn}>
                          For Individuals
                        </li>
                        <li data-test-id="upcoming-session" onClick={this.redirectToComingSoonPage}>On Demand Coaching</li>
                      </ul>
                    </div>
                  </Typography>
                  <Typography
                    id="howtoworks"
                    onClick={this.handleWorks}
                    ref={this.scrollRefWorks}
                    data-test-id="how-it-works-btn"
                    className={
                      this.state.scrolling
                        ? `${classes.textBetween} ${classes.text_color_purple}`
                        : `${classes.textBetween} ${classes.text_color_white}`
                    }
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "24.51px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    How it works
                  </Typography>
                  <Typography
                    className={
                      this.state.scrolling
                        ? `${classes.textBetween} ${classes.text_color_purple}`
                        : `${classes.textBetween} ${classes.text_color_white}`
                    }
                    onClick={this.handleRegisterNavigate}
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "18px",
                      fontWeight: 600,
                      lineHeight: "24.51px",
                      letterSpacing: "0em",
                      textAlign: "center",
                    }}
                  >
                    Join as a coach
                  </Typography>
                </div>

                <div
                  className={this.state.scrolling
                    ? `${classes.menuIcn} ${classes.menuIcn_purple}`
                    : `${classes.menuIcn} ${classes.menuIcn_white}`
                  }
                  data-test-id="dropDownID"
                >
                  {this.showMobileNavigationIcons()}
                  <div>
                    {this.state.dropMenuState && (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        className={
                          this.state.scrolling
                            ? `${"hover_dropdown"} ${
                                classes.text_color_purple
                              }`
                            : `${"hover_dropdown"} ${
                                classes.text_color_white
                              }`
                        }
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "217px",
                            right: "-30px",
                            top: "35px",
                            padding: "10px",
                            zIndex: "1",
                            borderRadius: "5px",
                            opacity: "1",
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                          }}
                          className={
                            this.hoverDivClass()
                          }
                        >
                          <Typography
                            style={{
                              textAlign: 'right',
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                              fontFamily: 'Open Sans',
                              fontSize: '18px',
                              fontWeight: '600',
                              lineHeight: '24.51px',
                            }}
                            onClick={this.handleServices}
                            className={
                              this.state.scrolling
                                ? `${"hmbgr_hover_dropdown"} ${
                                    classes.text_color_purple
                                  } `
                                : `${"hmbgr_hover_dropdown"} ${
                                    classes.text_color_white
                                  }`
                            }
                          >
                            Services
                            <span
                            data-test-id="mobileNavigationHandler"
                            style={{ transform: this.state.mobileNavigation ? "rotate(180deg)" : "rotate(0deg)" }}
                              onClick={this.mobileNavigationHandler}>
                              <KeyboardArrowDownOutlinedIcon />
                            </span>
                          </Typography>
                            {this.state.mobileNavigation &&
                            <div
                              className={
                                classes.mobileNavigation
                              }
                            >
                              <Typography
                                className={
                                  this.state.scrolling
                                    ? `${classes.text_color_purple}`
                                    : `${classes.text_color_white}`
                                }
                                style={{
                                  fontFamily: "Open Sans",

                                  fontSize: "18px",
                                  fontWeight: 600,
                                  lineHeight: "24.51px",
                                  letterSpacing: "0em",
                                  textAlign: "right",
                                }}
                                onClick={this.redirectToComingSoonPage}
                              >
                                For Business
                              </Typography>
                              <Typography
                                className={
                                  this.state.scrolling
                                    ? `${classes.text_color_purple}`
                                    : `${classes.text_color_white}`
                                }
                                style={{
                                  fontFamily: "Open Sans",

                                  fontSize: "18px",
                                  fontWeight: 600,
                                  lineHeight: "25px",
                                  letterSpacing: "0em",
                                  textAlign: "right",
                                }}
                                onClick={this.individualHandleBtn}
                              >
                                For Individuals
                              </Typography>
                              <Typography
                                className={
                                  this.state.scrolling
                                    ? `${classes.text_color_purple}`
                                    : `${classes.text_color_white}`
                                }
                                style={{
                                  fontFamily: "Open Sans",

                                  fontSize: "18px",
                                  fontWeight: 600,
                                  lineHeight: "25px",
                                  letterSpacing: "0em",
                                  textAlign: "right",
                                }}
                                onClick={this.redirectToComingSoonPage}
                              >
                                On Demand Coaching
                              </Typography>
                            </div>}
                            <Typography
                            onClick={this.handleWorks}
                            style={{
                              textAlign: "right",
                              fontFamily: "Open Sans",
                              fontSize: "18px",
                              fontWeight: "600",
                              lineHeight: "24.51px",
                            }}
                            >
                              How it works
                            </Typography>
                          <Typography
                            onClick={this.handleRegisterNavigate}
                            style={{
                              textAlign: 'right',
                              fontFamily: 'Open Sans',
                              fontSize: '18px',
                              fontWeight: '600',
                              lineHeight: '24.51px',
                            }}
                          >
                            Join as a coach
                          </Typography>
                          <div
                            style={{ cursor: "pointer", display: 'flex', justifyContent: 'end' }}
                            className="loginButton"
                          >
                            <button
                              className={
                                this.state.scrolling
                                  ? `${classes.text_color_purple}  hmbgr_login_purple`
                                  : `${classes.text_color_white}  hmbgr_login_white`
                              }
                              onClick={this.handleLoginNavigate}
                            >
                              <svg
                                width="18px"
                                height="18px"
                                viewBox="0 0 18 19"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18 3.3125V15.6875C18 17.2384 16.7384 18.5 15.1875 18.5H8.12109C6.57024 18.5 5.30859 17.2384 5.30859 15.6875V14.2812C5.30859 13.8929 5.62335 13.5781 6.01172 13.5781C6.40009 13.5781 6.71484 13.8929 6.71484 14.2812V15.6875C6.71484 16.4629 7.34573 17.0938 8.12109 17.0938H15.1875C15.9629 17.0938 16.5938 16.4629 16.5938 15.6875V3.3125C16.5938 2.53714 15.9629 1.90625 15.1875 1.90625H8.12109C7.34573 1.90625 6.71484 2.53714 6.71484 3.3125V4.71875C6.71484 5.10712 6.40009 5.42188 6.01172 5.42188C5.62335 5.42188 5.30859 5.10712 5.30859 4.71875V3.3125C5.30859 1.76164 6.57024 0.5 8.12109 0.5H15.1875C16.7384 0.5 18 1.76164 18 3.3125ZM7.94229 11.3211C7.66667 11.5947 7.66489 12.0399 7.93831 12.3155C8.07578 12.4541 8.25664 12.5234 8.4375 12.5234C8.61658 12.5234 8.79565 12.4555 8.93271 12.3195L10.5198 10.7449C10.8546 10.4127 11.0391 9.97063 11.0391 9.5C11.0391 9.02937 10.8546 8.58731 10.5198 8.25511L8.93271 6.6805C8.65709 6.40707 8.21187 6.40872 7.93831 6.68448C7.66489 6.9601 7.66667 7.40532 7.94229 7.67874L9.06921 8.79688H0.703125C0.314758 8.79688 0 9.11163 0 9.5C0 9.88837 0.314758 10.2031 0.703125 10.2031H9.06921L7.94229 11.3211Z"
                                />
                              </svg>
                              Login
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <LoginButtonWrapper>
                  <button
                    className={this.mainLoginClass()}
                    onClick={this.handleLoginNavigate}
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 3.3125V15.6875C18 17.2384 16.7384 18.5 15.1875 18.5H8.12109C6.57024 18.5 5.30859 17.2384 5.30859 15.6875V14.2812C5.30859 13.8929 5.62335 13.5781 6.01172 13.5781C6.40009 13.5781 6.71484 13.8929 6.71484 14.2812V15.6875C6.71484 16.4629 7.34573 17.0938 8.12109 17.0938H15.1875C15.9629 17.0938 16.5938 16.4629 16.5938 15.6875V3.3125C16.5938 2.53714 15.9629 1.90625 15.1875 1.90625H8.12109C7.34573 1.90625 6.71484 2.53714 6.71484 3.3125V4.71875C6.71484 5.10712 6.40009 5.42188 6.01172 5.42188C5.62335 5.42188 5.30859 5.10712 5.30859 4.71875V3.3125C5.30859 1.76164 6.57024 0.5 8.12109 0.5H15.1875C16.7384 0.5 18 1.76164 18 3.3125ZM7.94229 11.3211C7.66667 11.5947 7.66489 12.0399 7.93831 12.3155C8.07578 12.4541 8.25664 12.5234 8.4375 12.5234C8.61658 12.5234 8.79565 12.4555 8.93271 12.3195L10.5198 10.7449C10.8546 10.4127 11.0391 9.97063 11.0391 9.5C11.0391 9.02937 10.8546 8.58731 10.5198 8.25511L8.93271 6.6805C8.65709 6.40707 8.21187 6.40872 7.93831 6.68448C7.66489 6.9601 7.66667 7.40532 7.94229 7.67874L9.06921 8.79688H0.703125C0.314758 8.79688 0 9.11163 0 9.5C0 9.88837 0.314758 10.2031 0.703125 10.2031H9.06921L7.94229 11.3211Z"
                      />
                    </svg>
                    Login
                  </button>
                </LoginButtonWrapper>
              </div>
            </Grid>
          </div>

          <Grid
            item
            xs={12}
            style={{ marginTop: "-93px" }}
          >
            <Box className={classes.heroSectDiv}>
              <video autoPlay muted loop>
                <source src={landingPageVideo} type="video/mp4" />
                <source src={landingPageVideo} type="video/ogg" />
              </video>
              <MainContainer1 className={classes.heroSect}>
                <div>
                  <Typography className={classes.text} id="wecoachTitle">
                    We Coach
                  </Typography>
                  <div
                    style={{
                      position: "relative",
                      width: "auto",
                      marginTop: "15px",
                    }}
                  >
                    <div className="animation-container colorDesignOfANi">
                      <TypeWriter
                        options={{
                          autoStart: true,
                          loop: true,
                          strings: ["LEADERS", "TEAMS", "SENIOR EXECUTIVES"],
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ margin: "32px 0" }} className="animDownDescDiv">
                  <Typography className={classes.textSub} id="animationDown">
                    <span style={{ fontWeight: 700 }}>Coachy.sa</span> offers a
                    transformative coaching experience to help
                    <br />
                    you achieve measurable individual and organizational
                    results.
                  </Typography>
                </div>
                <div className={classes.btnDiv}>
                  <div>
                    <button className="slideLeft " onClick={this.redirectToComingSoonPage}>For Business</button>
                  </div>
                  <button
                      className="forIndividuals"
                      onClick={this.individualHandleBtn}
                    >
                      For Individuals
                    </button>
                </div>
              </MainContainer1>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <MainContainer1 maxWidth="lg">
            <div className={classes.service} ref={this.scrollNavbarRef}>
              <DotContainer><Box className="circle">OU</Box>R SERVICES</DotContainer>
            </div>
            </MainContainer1>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.cardOneBox} id="cardOneDiv">
              <MainContainer1 maxWidth="lg">
                <Box className="card-one-end">
              <Card id="cardOne" className={classes.cardOne}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0
                  }}
                  className="cardContent"
                >
                  <Typography
                    id="urServices"
                    variant="h4"
                    style={{
                      fontSize: "32px",
                      fontWeight: 600,
                      lineHeight: "38.4px",
                      fontFamily: "Martian Mono",
                      marginBottom: "24px",
                    }}
                    className="cardTitle"
                  >
                    FOR BUSINESS
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "#EDEFEE",
                      fontFamily: "Open Sans",
                      lineHeight: "27.24px",
                    }}
                    className="cardDesc"
                  >
                    Coachy.sa enables your leaders to change their mindset and
                    adopt new behaviors to impact business results.
                  </Typography>

                  <ul
                    style={{
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      marginLeft: "-26px",
                      color: "#EDEFEE",
                      fontFamily: "Open Sans",
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                    className="cardBullets"
                  >
                    <li>Diverse Pool of experienced Coaches</li>
                    <li>Coaching management system</li>
                    <li>Tailored Coaching Programs</li>
                    <li>Seamless Integration</li>
                  </ul>
                  <button id="startNow" className="hoverStartNow" onClick={this.redirectToComingSoonPage}>
                    Start Now
                  </button>
                </CardContent>
              </Card>
              </Box>
              </MainContainer1>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box id="cardTwoBox" className={classes.cardTwoBox}>
              <MainContainer1 maxWidth="lg">
                <Box className="card-two-end">
              <Card id="cardTwo" className={classes.cardTwo}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0
                  }}
                  className="cardContent"
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: "32px",
                      fontWeight: 600,
                      marginBottom: "24px",
                      lineHeight: "38.4px",
                      fontFamily: "Martian Mono",
                    }}
                    className="cardTitle"
                  >
                    FOR INDIVIDUALS
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "#EDEFEE",
                      fontFamily: "Open Sans",
                      lineHeight: "27.24px",
                    }}
                    className="cardDesc"
                  >
                    Elevate your leadership potential with the support of our
                    qualified Executive Coaches.
                  </Typography>

                  <ul
                    style={{
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      marginLeft: "-26px",
                      color: "#EDEFEE",
                      fontFamily: "Open Sans",
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                    className="cardBullets"
                  >
                    <li>Latest coaching approaches</li>
                    <li>Convenient & Accessible anywhere</li>
                    <li>Goal-Setting and Progress Tracking</li>
                    <li>Ongoing support</li>
                  </ul>
                  <button
                    className="findCoach"
                    onClick={this.handleForIndivisual}
                    data-test-id="find-coach-btn"
                  >
                    Find your Coach
                  </button>
                </CardContent>
              </Card>
              </Box>
              </MainContainer1>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box id="cardOneDiv" className={classes.cardThreeBox}>
            <MainContainer1 maxWidth="lg">
                <Box className="card-one-end">
              <Card id="cardOne" className={classes.cardOne}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0
                  }}
                  className="cardContent"
                >
                  <Typography
                    variant="h4"
                    style={{
                      fontSize: "32px",
                      fontWeight: 600,
                      fontFamily: "Martian Mono",
                      lineHeight: "38.4px",
                      marginBottom: "24px"
                    }}
                    className="cardTitle"
                  >
                    FOR COACHES
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "#EDEFEE",
                      fontFamily: "Open Sans",
                      lineHeight: "27.24px",
                    }}
                    className="cardDesc"
                  >
                    Manage your coaching business through smart and interactive
                    solution that helps you to focus on your client and enable
                    them to achieve results.
                  </Typography>

                  <ul
                    style={{
                      fontWeight: 400,
                      fontSize: "18px",
                      lineHeight: "28.8px",
                      marginLeft: "-26px",
                      color: "#EDEFEE",
                      fontFamily: "Open Sans",
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                    className="cardBullets"
                  >
                    <li>Scale your coaching business</li>
                    <li>Seamless coaching experience</li>
                    <li>All-in-One CRM Solution</li>
                  </ul>
                  <button
                    className="joinUs"
                    onClick={this.handleRegisterNavigate}
                  >
                    Join Us
                  </button>
                </CardContent>
              </Card>
              </Box>
              </MainContainer1>
            </Box>
          </Grid>

          <Grid
            ref={this.positionRefWorks}
            item
            xs={12}
            className={classes.howItWork}
          >
            <MainContainer1>
            <DotContainer style={{marginBottom:"20px"}}><Box className="circle">Ho</Box>W IT WORKS?</DotContainer>
            <Box className="itWorksDivdesc">
              <Typography className={classes.subText1} id="headerTitileDesc">
                Seamless and secure coaching solution that caters to your
                development needs whenever, wherever and however format you
                prefer
              </Typography>
            </Box>
            </MainContainer1>
          </Grid>
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            <Grid item xs={12} md={4}>
              <Box className={classes.bussCard}>
                <Typography
                  className="cardTitle"
                  style={{
                    fontWeight: 600,
                    fontSize: "32px",
                    marginTop: "350px",
                    marginBottom: "4px",
                    fontFamily: "Martian Mono",
                    lineHeight: "38.4px",
                  }}
                >
                  FOR BUSINESS
                </Typography>
                <Typography
                  className="cardSubTitle"
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    marginBottom: "20px",
                    fontFamily: "Open Sans",
                    lineHeight: "27.24px"
                  }}
                >
                  Coaching management system
                </Typography>
                <button className="learnMoreOne " onClick={this.redirectToComingSoonPage}>Learn More</button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.individualCard}>
                <Typography
                  className="cardTitle"
                  style={{
                    fontWeight: 600,
                    fontSize: "32px",
                    marginTop: "350px",
                    marginBottom: "4px",
                    fontFamily: "Martian Mono",
                    lineHeight: "38.4px"
                  }}
                >
                  FOR INDIVIDUALS
                </Typography>
                <Typography
                  className="cardSubTitle"
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    marginBottom: "20px",
                    fontFamily: "Open Sans",
                    lineHeight: "27.24px"
                  }}
                >
                  Diverse Expert Coaches
                </Typography>
                <button
                  className="learnMoreTwo"
                  onClick={this.handleForIndivisual}
                >
                  Learn More
                </button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className={classes.coachCard}>
                <Typography
                  className="cardTitle"
                  style={{
                    fontWeight: 600,
                    fontSize: "32px",
                    marginTop: "350px",
                    marginBottom: "4px",
                    fontFamily: "Martian Mono",
                    lineHeight: "38.4px"
                  }}
                >
                  FOR COACHES
                </Typography>
                <Typography
                  className="cardSubTitle"
                  style={{
                    fontWeight: 600,
                    fontSize: "20px",
                    marginBottom: "20px",
                    fontFamily: "Open Sans",
                  }}
                >
                  All-in-One CRM Solution
                </Typography>
                <button
                  className="learnMoreThree"
                  onClick={this.handleRegisterNavigate}
                >
                  Learn More
                </button>
              </Box>
            </Grid>
          </div>
          <ContactUsWrapper>
            <div className="contactBox" ref={this.contactUsRef}>
              <MainContainer maxWidth="lg">
                <Box className="form-wrapper">
                <div className="left-content">
                  <Box className="descContactTitle">
                    <div className={classes.contactContent} id="contactUs">
                      CONTACT US
                    </div>
                    <div className={classes.contactDescri} id="contactUsDesc">
                      We would love to hear from you! Whether you have a
                      question, a suggestion, or just want to say hello, our
                      team is always ready to assist you.
                    </div>
                  </Box>
                </div>
                <div className="right-content">
                  <Box className="right-max-width-content">
                  <Formik
                    initialValues={{
                      captchaValue: this.state.captchaDone,
                      email: this.state.email,
                      fullName: this.state.name,
                      phoneNumber: this.state.number,
                      description: this.state.description,
                    }}
                    validationSchema={this.validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                      this.contactUsLanding({
                        description: values.description,
                        email: values.email,
                        fullName: values.fullName,
                        phoneNumber: values.phoneNumber,
                        captchaValue: values.captchaValue,
                      });
                    }}
                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="container">
                          <div
                            style={{
                              margin: "auto",
                              maxWidth: "100%",
                              display: "flex",
                              flexDirection: "column",
                              gap: "24px",
                            }}
                            className="contactUsDiv"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <label className="FormDefineContactLabel">
                                Full Name
                              </label>
                              <input
                                style={{ height: "48px" }}
                                type="text"
                                id="fname"
                                name="fullName"
                                data-test-id="nametest"
                                value={values.fullName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {this.displayTouchedErrorMessage(errors.fullName,touched.fullName)}
                            </div>
                            <div className="input-wrapper">
                              <div className="left-input-wrapper">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                  }}
                                >
                                  <label className="FormDefineContactLabel">
                                    Email
                                  </label>
                                  <input
                                    style={{ height: "48px" }}
                                    type="text"
                                    id="lname"
                                    name="email"
                                    data-test-id="emailtest"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {this.displayTouchedErrorMessage(errors.email,touched.email)}
                                </div>
                              </div>
                              <div className="right-input-wrapper">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                  }}
                                >
                                  <label className="FormDefineContactLabel">
                                    Phone Number
                                  </label>
                                  <input
                                    style={{ height: "48px" }}
                                    type="number"
                                    id="lname"
                                    name="phoneNumber"
                                    data-test-id="phonetest"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                 {this.displayTouchedErrorMessage(errors.phoneNumber,touched.phoneNumber)}
                                </div>
                              </div>
                            </div>
                            <div className="description-input-field">
                              <label className="FormDefineContactLabel">
                                How can we help you?{" "}
                                <span className="FormDefineContactLabelopt">
                                  Optional
                                </span>
                              </label>
                              <textarea
                                data-test-id="descriptiontest"
                                id="subject"
                                name="description"
                                placeholder="Type your message..."
                                style={{ height: "200px", resize: "none" }}
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></textarea>
                              {this.displayTouchedErrorMessage(
                                errors.description,
                                touched.description
                              )}
                            </div>
                            <div className="captcha"
                              style={{
                                
                              }}
                            >
                              <CustomReCAPTCHA
                                //@ts-ignore
                                ref={this.captcha}
                                sitekey={this.state.key}
                                data-test-id="captchatest"
                                onChange={(event) =>
                                  setFieldValue("captchaValue", event)
                                }
                              />
                              {this.displayErrorMessage(errors.captchaValue)}
                            </div>
                            <div>
                              <input
                                type="submit"
                                value="Submit"
                                className="formSubmit"
                                data-test-id="form-submit"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  </Box>
                </div>
                </Box>
              </MainContainer>
            </div>
          </ContactUsWrapper>         
          <Grid item xs={12}><Footer data-test-id="footer" navigateToContactUsPage={this.navigateToContactusPage} navigation={this.props.navigation} topToScroll={this.scrollToTop}/></Grid>
        </Grid>
        <Modal
          open={this.state.showSuccessModal}
          onClose={this.handleModalBackClick}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={stylede}>
            <Headings>Submitted Successfully</Headings>
            <Box style={styles.modalContent}>
              <img src={EmailSuccessIcon} alt="Previous" style={styles.icon} />
              <SubHeadingsStyle>
                Your message has been submitted successfully
              </SubHeadingsStyle>
              <VerifyButtonStyle
                data-test-id={"btnVerify"}
                onClick={this.handleModalBackClick}
              >
                Close
              </VerifyButtonStyle>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.showSuccessModalSec}
          onClose={this.handleModalBackClickSec}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modalLanding"
        >
          <Box className="modalBox">
            <div
              style={{
                maxWidth: "100%",
                whiteSpace: "nowrap" as "nowrap",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <div className={classes.modalCircle} id="circle"></div>
              <Typography className={classes.circleText} id="circleName">
                YO<span style={{ color: "#3D0E9C" }}>U ARE LOOKING FOR</span>
              </Typography>
            </div>
            <Box style={styles.modalContent}>
              <VerifyButtonStylea
                className="pasddingFix16px"
                style={{ padding: "10px, 16px, 10px, 16px" }}
                data-test-id={"btnVerify"}
              >
                Coaching for my organisation
              </VerifyButtonStylea>
              <VerifyButtonStyleb
                className="pasddingFix16px"
                style={{ padding: "10px, 16px, 10px, 16px" }}
                data-test-id="navigateToExecutiveCreationPage"
                onClick={this.navigateToExecutiveCreationPage}
              >
                Coaching for myself
              </VerifyButtonStyleb>
              <VerifyButtonStylec
                className="pasddingFix16px"
                style={{ padding: "10px, 16px, 10px, 16px" }}
                data-test-id="navigateToCoachPage"
                onClick={this.navigateToCoachPage}
              >
                Joining coachy as a coach
              </VerifyButtonStylec>
              <div style={{ marginTop: "6%" }}>
                <span
                  className="help"
                  style={{
                    fontFamily: "Martian Mono",
                    fontSize: "18px",
                    fontWeight: 300,
                    lineHeight: "21.6px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#390689",
                  }}
                >
                  Need help?
                </span>{" "}
                <span
                  className="helpContactUs"
                  style={{
                    fontFamily: "Martian Mono",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "21.6px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#390689",
                    cursor: "pointer",
                  }}
                  data-test-id="navigateToContactusPage"
                  onClick={this.navigateToContactusPage}
                >
                  Contact us
                </span>
              </div>
            </Box>
          </Box>
        </Modal>
      </React.Fragment>
    );
  }

  // Customizable Area End
}
export default withStyles(useStyles)(LandingPage);
const stylede = {
  background: "#EDEFEE",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "450px",
  bgcolor: "background.paper",
  borderRadius: "40px",
  boxShadow: 24,
  padding: "3%",
  p: 4,
};
const SubHeadingsStyle = styled(Typography)({
  fontSize: "18px",
  color: "#390689",
  fontFamily: "Open Sans",
  textAlign: "center",
  padding: "2%",
  "@media (max-width: 413px)": {
    fontSize: "11px",
  },
});
const ErrorTypography = styled(Typography)({
  margin: "1% 0%",
  color: "#FAFAFA",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  fontSize: "12px",
  "@media (max-width: 768px)": {
    fontSize: "10px",
  },
  "@media (max-width: 480px)": {
    fontSize: "8px",
  },
  "@media (max-width: 320px)": {
    fontSize: "7px",
  },
});

const ContactUsWrapper = styled("section")({
  width: "100%",
  "& .contactBox": {
    backgroundImage: `url(${contactUs})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    "@media(max-width:960px)":{
      minHeight: "901px",
      height: "auto",
    }
  },
  "& .input-wrapper": {
    gap: "24px",
    display: "flex",
    width: "100%",
    "& .left-input-wrapper": {
      flex: "0 0 calc(50% - 12px)"
    },
    "& .right-input-wrapper": {
      flex: "0 0 calc(50% - 12px)"
    },
  },
  "& .description-input-field": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .captcha": {
    marginTop: "10px",
    marginBottom: "30px",
  },
  "@media (max-width: 600px)": {
    "& .input-wrapper": {
      flexDirection: "column"
    },
  }
});
const Headings = styled(Typography)({
  fontSize: "22px",
  fontWeight: 700,
  color: "#390689",
  marginBottom: "2%",
  textAlign: "center",
  fontFamily: "Martian Mono",
  "@media (max-width: 1041px)": {
    fontSize: "24px",
  },
  "@media (max-width: 949px)": {
    fontSize: "22px",
  },
  "@media (max-width: 887px)": {
    fontSize: "20px",
  },
  "@media (max-width: 824px)": {
    fontSize: "18px",
  },
  "@media (max-width: 413px)": {
    fontSize: "16px",
  },
});
const VerifyButtonStyle = styled(Button)({
  backgroundColor: "#F46C3D",
  color: "#fff",
  fontSize: "16px",
  fontWeight: 700,
  marginTop: "3%",
  textTransform: "none" as "none",
  borderRadius: "30px",
  width: "60%",
  "@media (max-width: 380px)": {
    fontSize: "13px",
  },
  "&:hover": {
    backgroundColor: "#5F0AE5",
    color: "#fff",
  },
});
const VerifyButtonStylea = styled(Button)({
  background: "#00BDFF",
  width: "442px",
  height: "69px",
  lineHeight: "26px",
  color: "#fff",
  fontSize: "18px",
  fontWeight: 700,
  marginTop: "3%",
  fontFamily: "Open Sans",
  textTransform: "none" as "none",
  borderRadius: "30px",
  padding: "10px, 16px, 10px, 16px",
  whiteSpace: "nowrap",
  "@media (max-width: 380px)": {
    fontSize: "13px",
  },
  "@media (min-width: 601px) and (max-width: 1280px)": {
    width: "350px",
    fontSize: "18px",
    lineHeight: "25.41px",
  },
  "@media(max-width:600px)": {
    width: "100%",
    fontSize: "16px",
    lineHeight: "21.41px",
  },
  "&:hover": {
    backgroundColor: "#5F0AE5",
    color: "#fff",
  },
});
const VerifyButtonStyleb = styled(Button)({
  backgroundColor: "#24E09C",
  width: "442px",
  height: "69px",
  marginTop: "3%",
  color: "#fff",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "26px",
  textTransform: "none" as "none",
  whiteSpace: "nowrap",
  borderRadius: "30px",
  padding: "10px, 16px, 10px, 16px",
  fontFamily: "Open Sans",
  "@media (max-width: 380px)": {
    fontSize: "13px",
  },
  "@media (min-width: 601px) and (max-width: 1280px)": {
    width: "350px",
    lineHeight: "25.41px",
    fontSize: "18px",
  },
  "@media(max-width:600px)": {
    width: "100%",
    fontSize: "16px",
    lineHeight: "21.41px",
  },
  "&:hover": {
    color: "#fff",
    backgroundColor: "#5F0AE5",
  },
});
const VerifyButtonStylec = styled(Button)({
  padding: "10px, 16px, 10px, 16px",
  height: "69px",
  backgroundColor: "#F46C3D",
  width: "442px",
  color: "#fff",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 700,
  textTransform: "none" as "none",
  marginTop: "3%",
  borderRadius: "30px",
  fontFamily: "Open Sans",
  whiteSpace: "nowrap" as "nowrap",
  "@media (max-width: 380px)": {
    fontSize: "13px",
  },
  "@media (min-width: 601px) and (max-width: 1280px)": {
    lineHeight: "25.41px",
    width: "350px",
    fontSize: "18px",
  },
  "@media(max-width:600px)": {
    width: "100%",
    fontSize: "16px",
    lineHeight: "21.41px",
  },
  "&:hover": {
    backgroundColor: "#5F0AE5",
    color: "#fff",
  },
});
const styles = {
  modalContent: {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
  },
  icon: {
    width: "100px",
    height: "100px",
    marginBottom: "1rem",
    backgroundSize: "cover" as "cover",
    objectFit: "cover" as "cover",
  },
};
