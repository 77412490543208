import React from "react";
export const imgPasswordInVisible = require("../assets/processpng.jpeg");
export const profilePhotos = require("../assets/profilePhoto.jpeg");
export const details = require("../assets/details.jpeg");
export const pmessage = require("../assets/pmessage.jpeg");
export const logout = require("../assets/logout.jpeg");
export const image_icon = require("../assets/imagebutton_ico.svg");
export const imagebutton_icon = require("../assets/imagebutton_icon.svg");
export const img1 = require("../assets/img1.svg");
export const img2 = require("../assets/img2.svg");
export const img3 = require("../assets/img3.svg");
export const img4 = require("../assets/img4.svg");
export const imgIcon = require("../assets/imgIcon.svg");
export const imgCircleIcon = require("../assets/imgCircleIcon.svg");
export const imgCircleIconTwo = require("../assets/imfCircleIconTwo.svg");
export const more = require("../assets/more.svg");
export const crosh = require("../assets/crosh.svg");
export const search = require("../assets/search.svg");
export const uploadImage = require("../assets/uploadImg.svg");
export const avtarPerson = require("../assets/avtar.svg");
export const searchBar = require("../assets/searchBar.svg");
export const leftArrow = require("../assets/leftArrow.svg");
export const booked = require("../assets/booked.svg");
export const fileIcon = require("../assets/fileIcon.svg");
export const checkSuccess = require("../assets/checkSuccess.svg");
export const checkTrue = require("../assets/checkTrue.svg");
export const viewProfile = require("../assets/viewProfile.svg");
export const Setting = require("../assets/Setting.svg");
export const question = require("../assets/question.svg");
export const word = require("../assets/word.svg");
export const pdf = require("../assets/pdf.svg");
export const BookSession = require("../assets/BookSession.svg");
export const TakeSurvey = require("../assets/takeSurvey.svg");
export const ViewTasks = require("../assets/ViewTasks.svg");
export const MyGoals = require("../assets/MyGoals.svg");
export const downloadImage = require("../assets/image_download.png");
export const exitImgLogout = require("../assets/exitLogout.svg");
export const tooltipArrow = require("../assets/tooltipArrow.png");
export const coach = require("../assets/coach.png");
export const coachP = require("../assets/coachP.svg");
export const personal = require("../assets/personal.png");
export const professional = require("../assets/professional.png");
export const qualification = require("../assets/qualification.png");
export const bank = require("../assets/bank.png");
export const download = require("../assets/download.svg");
export const bankAct = require("../assets/bankAct.svg");
export const coachAct = require("../assets/coachAct.svg");
export const profAct = require("../assets/profAct.svg");
export const pers = require("../assets/pers.svg");
export const PdfIcon = require("../assets/PdfIcon.svg");
export const DownloadGreen = require("../assets/DownloadGreen.svg");
export const activeBtn = require("../assets/activeBtn.svg");
export const facebook = require("../assets/facebook.svg");
export const insta = require("../assets/insta.svg");
export const linkdin = require("../assets/linkdin.svg");
export const twitter = require("../assets/twitter.svg");
export const arrowIcon = require("../assets/arrowIcon.png");
export const whiteTriangle = require("../assets/whiteTriangle.png");
export const downloadImageIcon = require("../assets/downloadImage.png");
export const checkboxIcon = require("../assets/checkbox.png");
export const checkboxIconChecked = require("../assets/checkboxIconChecked.png");
export const errors= require("../assets/errors.png");
export const previewIcon = require("../assets/previewIcon.png");
export const filesIcon = require("../assets/fileIcon.png");
export const logoIcon = require("../assets/logoIcon.png");
export const coachyIcon = require("../assets/coachyIcon.png");
export const redirectIcon = require("../assets/redirectIcon.png");
export const linkIcon = require("../assets/linkIcon.png");

export const downArrowComponent =  require('../assets/down_arrow_icon.svg');

export const uploadFileComponentDark = require("../assets/upload_icon_dark.svg");
export const uploadFileComponentGreen = require("../assets/upload_icon_dark.svg");
export const emailIconComponent = require("../assets/email_icon.svg");
export const languageIconComponent = require("../assets/language_icon.svg");
export const fileAttachmentComponent = require("../assets/file_attachments.svg")

export const accordionDownIcon = require("../assets/accordion_down.svg");
export const iconn = require("../assets/iconn.svg");
export const first = require("../assets/first.png");
export const second = require("../assets/firstN.png");
export const image1 = require("../assets/secondN.png");
export const image2 = require("../assets/thirdN.png");
export const image3 = require("../assets/fourN.png");
export const image4 = require("../assets/fifthN.png");
export const image5 = require("../assets/sixN.png");
export const image6 = require("../assets/sevenN.png");
export const image7 = require("../assets/eightN.png");
export const image8 = require("../assets/tenN.png");
export const image9 = require("../assets/nineN.png");
export const checkedCheckbox = require("../assets/checked_checkbox.svg");
export const unCheckedCheckbox = require("../assets/un_checked_checkbox.svg");
export const attachments = require('../assets/attachment.svg')

export const pdfSvgIcon = require("../assets/pdf_icon.svg");

export const wordSvgIcon = require("../assets/word_icon.svg");
export const upDownicon = require("../assets/upDownIcon.svg")

export const rtfSvgIcon = require("../assets/rtf_icon.svg");

export const txtSvgIcon = require("../assets/txt_icon.svg")
export const crossIcon = require("../assets/crossIcon.svg")
export const pdfFile = require("../assets/pdfFile.svg");
export const wordIcon = require('../assets/word.png');
export const pngIcon = require('../assets/pngFile.svg');
export const xlsxIcon = require('../assets/xlsxFile.svg');
export const pdficonNew = require('../assets/PdfIcon.svg');
export const jpgIcon = require('../assets/jpegFile.svg');
export const docxIcon = require('../assets/wordIcon.svg');
export const downloadNewGreen = require("../assets/DownloadGreen.svg");