import { Route, Redirect, Switch } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap }) => {
  return (
    <Switch>
      {Object.keys(routeMap).map(route => {
        const currentRoute = routeMap[route];
        const Component = currentRoute.component;
        if (currentRoute.modal) {
          return (
            <ModalRoute
              key={currentRoute.path}
              path={currentRoute.path}
              component={(props) => (
                <Wrapper element={<Component />} {...props} routeMap={routeMap} />
              )}
            />
          );
        } else if (currentRoute.wrapRoute === "protectedRouteForCoach") {
          const user = localStorage.getItem("userType");
          const token = localStorage.getItem("token");
          const getCoachProfileStatus = localStorage.getItem("coach_profile_status");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token && user === "coach") {
                if (getCoachProfileStatus === "pending") {
                  return <Redirect
                    to={{
                      pathname: "/AssessmentTest",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                } else if (getCoachProfileStatus === "awaiting") {
                  return <Redirect
                    to={{
                      pathname: "/CoachAdministration",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                } else if (getCoachProfileStatus === 'accepted') {
                  return (
                    <Redirect
                      to={{
                        pathname: "/Dashboard",
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                }
                return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/EmailAccountLoginBlock",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        } else if (currentRoute.wrapRoute === "protectedCoachForAssessment") {
          const getCoachProfileStatus = localStorage.getItem("coach_profile_status");
          const user = localStorage.getItem("userType");
          const token = localStorage.getItem("token");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token && user === "coach") {
                if (getCoachProfileStatus == 'awaiting' && currentRoute.path != '/CoachAdministration') {
                  return (
                    <Redirect
                      to={{
                        pathname: "/CoachAdministration",
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                } else if (getCoachProfileStatus == 'pending' && currentRoute.path != '/AssessmentTest') {
                  return (
                    <Redirect
                      to={{
                        pathname: "/AssessmentTest",
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                } else if (getCoachProfileStatus == 'accepted' && currentRoute.path != '/Dashboard') {
                  return (
                    <Redirect
                      to={{
                        pathname: "/Dashboard",
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                } else if (getCoachProfileStatus == 'accepted_available') {
                  return (
                    <Redirect
                      to={{
                        pathname: "/DashboardHome",
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                }
                return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/EmailAccountLoginBlock",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        } else if (currentRoute.wrapRoute === "protectedCoachForPostAssessment") {
          const getCoachProfileStatus = localStorage.getItem("coach_profile_status");
          const user = localStorage.getItem("userType");
          const token = localStorage.getItem("token");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token && user === "coach") {
                if (getCoachProfileStatus == "pending") {
                  return <Redirect
                    to={{
                      pathname: "/AssessmentTest",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                } else if (getCoachProfileStatus == "awaiting") {
                  return <Redirect
                    to={{
                      pathname: "/AssessmentTest",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                // } else if (getCoachProfileStatus == "accepted") {
                //   return <Redirect
                //     to={{
                //       pathname: "/Dashboard",
                //       state: {
                //         from: props.location
                //       }
                //     }}
                //   />
                }
                if (getCoachProfileStatus === 'accepted_available') {
                  return (
                    <Redirect
                      to={{
                        pathname: "/DashboardHome",
                        state: {
                          from: props.location
                        }
                      }}
                    />
                  );
                }
                return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/EmailAccountLoginBlock",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        } else if (currentRoute.wrapRoute === "protectedRouteForExecutive") {
          const getClientProfileStatus = localStorage.getItem("client_profile_status");
          const user = localStorage.getItem("userType");
          const token = localStorage.getItem("token");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token && user === "executive") {
                if (getClientProfileStatus === "active") {
                  return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
                }
                return <Redirect
                  to={{
                    pathname: "/CoachSelection",
                    state: {
                      from: props.location
                    }
                  }}
                />
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/EmailAccountLoginBlock",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        } else if (currentRoute.wrapRoute === "protectedExecutiveForAssessment") {
          const getClientProfileStatus = localStorage.getItem("client_profile_status");
          const user = localStorage.getItem("userType");
          const token = localStorage.getItem("token");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token && user === "executive") {
                if(currentRoute.path == "/CoachSelectionProfile") {
                  return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
                }

                if(getClientProfileStatus === "active") {
                    return <Redirect
                    to={{
                      pathname: "/ClientDashboard",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                }
                return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/EmailAccountLoginBlock",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        } else if (currentRoute.wrapRoute === "protectedRouteForExecutiveSelectCoach") {
          const getClientProfileStatus = localStorage.getItem("client_profile_status");
          const user = localStorage.getItem("userType");
          const token = localStorage.getItem("token");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token && user === "executive") {
                return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: "/EmailAccountLoginBlock",
                      state: {
                        from: props.location
                      }
                    }}
                  />
                );
              }
            }}
          />
        } else if (currentRoute.wrapRoute === "authRoute") {
          const token = localStorage.getItem("token");
          return <Route
            key={currentRoute.path}
            path={currentRoute.path}
            exact={currentRoute.exact}
            render={props => {
              if (token) {
                const user = localStorage.getItem("userType");
                const pathname = user == 'coach' ? "/DashboardHome" : "/ClientDashboard"
                return <Redirect
                  to={{
                    pathname,
                    state: {
                      from: props.location
                    }
                  }}
                />
              } else {
                return (
                  <Wrapper element={<Component />} {...props} routeMap={routeMap} />
                );
              }
            }}
          />
        } else {
          return (
            <Route
              key={currentRoute.path}
              path={currentRoute.path}
              exact={currentRoute.exact}
              render={props => (
                <Wrapper element={<Component />} {...props} routeMap={routeMap} />
              )}
            />
          );
        }
      })}
      <Route
        path="*"
        exact
        render={() => {
          const token = localStorage.getItem("token");
          const user = localStorage.getItem("userType");
          if (token) {
            if (user === "coach") {
              return <Redirect to="/DashboardHome" />;
            } else {
              return <Redirect to="/ClientDashboard" />;
            }
          }
          return <Redirect to="/EmailAccountLoginBlock" />;
        }}
      />
    </Switch>
  );
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
